import { render, staticRenderFns } from "./ContributorsLabel.vue?vue&type=template&id=db1c2d6a&scoped=true&"
import script from "./ContributorsLabel.vue?vue&type=script&lang=js&"
export * from "./ContributorsLabel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db1c2d6a",
  null
  
)

export default component.exports