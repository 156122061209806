import { render, staticRenderFns } from "./TipUnbond.vue?vue&type=template&id=c6b2bfea&scoped=true&"
import script from "./TipUnbond.vue?vue&type=script&lang=js&"
export * from "./TipUnbond.vue?vue&type=script&lang=js&"
import style0 from "./TipUnbond.vue?vue&type=style&index=0&id=c6b2bfea&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b2bfea",
  null
  
)

export default component.exports